<template>
  <main class="reseller-settings page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="des-title">{{ $t("Reseller Tour Settings") }}</p>
    <div class="table-content-part">
      <div class="search-bar">
        <p class="search-label">{{ $t("Search") }}</p>
        <v-text-field
          v-model="search"
          single-line
          hide-details
          class="search-form"
        ></v-text-field>
      </div>
      <v-data-table :headers="headers" :items="reseller_settings" class="cus-table" :search="search">
        <template v-slot:[`item.action`]="{ item }">
          <div class="action-btn-group">
            <v-icon
              medium
              color="dark"
              class="mr-2"
              @click="openEditModal(item.id)"
              :title="$t('Edit')"
            >
              edit
            </v-icon>
            <v-icon
              medium
              color="dark"
              @click="deleteSetting(item.id)"
              v-b-modal.confirm-2
              :title="$t('Delete')"
            >
              delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
    <a class="link category__button new-btn" @click="openCreatModal()">{{ $t("New") }}</a>

    <modal name="CEModal" class="CEModal reSellerSetting-modal modal-scroll-bar">
      <div class="modal-content">
        <h2 v-if="isEdit" class="modal-title">{{ $t("Edit Reseller Settings") }}</h2>
        <h2 v-else class="modal-title">{{ $t("Add Reseller Settings") }}</h2>
        <div class="mb-10">
          <label class="label mb-2">{{ $t("Reseller ID") }}</label>
          <input class="input" type="text" v-model="addform.reseller_id" >
        </div>
        <div class="mb-10">
          <label class="label mb-2">{{ $t("Direct Sale Fee") }}</label>
          <input class="input" type="text" v-model="addform.direct_sale_fee" >
        </div>
        <div class="mb-10">
          <label class="label mb-2">{{ $t("Affiliate Sale Fee") }}</label>
          <input class="input" type="text" v-model="addform.affiliate_sale_fee" >
        </div>
        <div class="mb-10">
          <label class="label mb-2">{{ $t("Credit Sale Fee") }}</label>
          <input class="input" type="text" v-model="addform.credit_sale_fee" >
        </div>
        <div class="mb-10">
          <label class="label mb-2">{{ $t("SecondLink Sale Fee") }}</label>
          <input class="input" type="text" v-model="addform.link_sale_fee" >
        </div>
        <div class="mb-10">
          <label class="label mb-2">{{ $t("Front Sale Fee") }}</label>
          <input class="input" type="text" v-model="addform.second_link_sale_fee" >
        </div>
        <div class="mb-10">
          <label class="label mb-2">{{ $t("DirectFront Sale Fee") }}</label>
          <input class="input" type="text" v-model="addform.direct_front_sale_fee" >
        </div>
        <div class="modal-footer">
          <a class="link category__button cancel-btn" @click="closeCEModal()">{{ $t("Cancel") }}</a>
          <a class="link category__button create-btn" @click="editSetting()" v-if="isEdit">{{ $t("Edit") }}</a>
          <a class="link category__button create-btn" @click="addSetting()" v-else>{{ $t("Add") }}</a>
        </div>
      </div>
    </modal>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "ResellerSettings",
  components: {
    Loading 
  },
  data() {
    return {
      search: "",
      isEdit: false,
      headers: [
        {
          text: this.$t("Reseller ID"),
          align: "center ",
          sortable: false,
          value: "reseller_id"
        },
        {
          text: this.$t("Direct Sale Fee"),
          align: "center ",
          sortable: false,
          value: "direct_sale_fee"
        },
        {
          text: this.$t("Affiliate Sale Fee"),
          align: "center ",
          sortable: false,
          value: "affiliate_sale_fee"
        },
        {
          text: this.$t("Credit Sale Fee"),
          align: "center ",
          sortable: false,
          value: "credit_sale_fee"
        },
        {
          text: this.$t("Link Sale Fee"),
          align: "center ",
          sortable: false,
          value: "link_sale_fee"
        },
        {
          text: this.$t("SecondLink Sale Fee"),
          align: "center ",
          sortable: false,
          value: "second_link_sale_fee"
        },
        {
          text: this.$t("Front Sale Fee"),
          align: "center ",
          sortable: false,
          value: "front_sale_fee"
        },
        {
          text: this.$t("DirectFront Sale Fee"),
          align: "center ",
          sortable: false,
          value: "direct_front_sale_fee"
        },
        {
          text: this.$t("Action"),
          align: "center ",
          sortable: false,
          value: "action"
        },
      ],
      addform: {
        reseller_id: "",
        direct_sale_fee: "",
        affiliate_sale_fee: "",
        credit_sale_fee: "",
        link_sale_fee: "",
        second_link_sale_fee: "",
        front_sale_fee: "",
        direct_front_sale_fee: ""
      },
      editId: ""
    };
  },
  computed: {
    ...mapState({
      reseller_settings: state => state.reseller_settings.reseller_settings,
      reseller_one_setting: state => state.reseller_settings.reseller_one_setting,
      loading: state => state.reseller_settings.loading

    }),
    
  },
  async created() {
    if (this.reseller_settings == "") {
      await this.getAllData();
    }
  },
  mounted () {
    document.addEventListener('click', this.close);
  },
  beforeDestroy () {
    document.removeEventListener('click',this.close);
  },
  methods: {
    ...mapActions("reseller_settings", {
      getAllData: "getAllData",
      getOneSetting: "getOneSetting",
      update_reseller_settings: "update_reseller_settings",
      add_reseller_settings: "add_reseller_settings",
      delete_reseller_settings: "delete_reseller_settings"
    }),

    /* search tags and reset search */
    reset_addForm() {
      this.addform.reseller_id = "";
      this.addform.direct_sale_fee = "";
      this.addform.affiliate_sale_fee = "";
      this.addform.credit_sale_fee = "";
      this.addform.link_sale_fee = "";
      this.addform.second_link_sale_fee = "";
      this.addform.front_sale_fee = "";
      this.addform.direct_front_sale_fee = "";
    },
    /*  */

   /*  add, edit modal part */
   /*  add new destination */
    openCreatModal() {
      this.isEdit = false;
      this.$modal.show("CEModal");
    },
    async openEditModal(id) {
      this.isEdit = true;
      await this.getOneSetting({ id: id });
      this.editId = id;
      this.addform.reseller_id = this.reseller_one_setting.reseller_id;
      this.addform.direct_sale_fee = this.reseller_one_setting.direct_sale_fee;
      this.addform.affiliate_sale_fee = this.reseller_one_setting.affiliate_sale_fee;
      this.addform.credit_sale_fee = this.reseller_one_setting.credit_sale_fee;
      this.addform.link_sale_fee = this.reseller_one_setting.link_sale_fee;
      this.addform.second_link_sale_fee = this.reseller_one_setting.second_link_sale_fee;
      this.addform.front_sale_fee = this.reseller_one_setting.front_sale_fee;
      this.addform.direct_front_sale_fee = this.reseller_one_setting.direct_front_sale_fee;
      this.$modal.show("CEModal");
    },
    async addSetting() {
      await this.add_reseller_settings({ params: this.addform });
      this.closeCEModal(); 
      this.reset_addForm();
    },
    async editSetting() {
      await this.update_reseller_settings({ id: this.editId, params: this.addform });
      this.closeCEModal(); 
      this.reset_addForm();
    },
    deleteSetting(id) {
      this.delete_reseller_settings({ id: id });
    },
    closeCEModal() {
      this.$modal.hide("CEModal");
    }
  }
};
</script>

<style scoped lang="scss">
  .reseller-settings {
    width: unset;
    height: 100%;
    text-align: center;
    color: #002036;
    position: relative;
  }

  .des-title {
    font-size: 30px;
    margin-top: 10px;
  }

  .new-btn {
    color: #fff;
    background: #E2B629;
    margin: 10px 50px;
    border-radius: 4px;
  }

  .action-btn-group {
    display: flex;
  }
</style>
